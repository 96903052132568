<template>
  <v-content>
    <AuthForm />
  </v-content>
</template>

<script>
  import AuthForm       from './_components/Form'

  export default{
    name: 'AuthModule',
    components:{
      AuthForm,
    }
  }
</script>

<style scoped>
</style>
