<template>
  <v-container grid-list-md>
    <v-row align="center" justify="center">
      <v-col cols="10" md="6">
        <v-form method="post" @keyup.native.enter="recaptcha">

          <v-card class="pa-2">
            <v-card-title class="justify-center logo-container">
              <img
                src="@/assets/logo/logo.svg"
                class="logo-img"
              />
              <span class="pl-2">TEACHER</span>
            </v-card-title>

            <v-card-text v-if="true === showForm">

              <v-text-field
                v-model="username"
                :label="translations.login.username.title"
                @keyup="attempt = false"
                :error-messages="usernameErrors"
                class="body-2"
                autocomplete="email"
              ></v-text-field>

              <v-text-field
                v-model="password"
                :append-icon="passwordVisible ? 'fas fa-eye' : 'fas fa-eye-slash'"
                @click:append="() => passwordVisible = !passwordVisible"
                :type="passwordVisible ? 'text' : 'password'"
                @keyup="attempt = false"
                min="6"
                :label=translations.login.password.title
                name="password"
                :error-messages="passwordErrors"
                counter
                autocomplete="current-password"
              ></v-text-field>

              <p class="caption text-xs-left pb-2">
                Este sitio está protegido por reCAPTCHA y se aplica la <a href="https://policies.google.com/privacy" target="_blank">Política de privacidad</a> y los <a href="https://policies.google.com/terms" target="_blank">Términos de servicio</a> de Google
              </p>

              <v-col cols="12" align="left" class="text-xs-left">
                <router-link
                  :to="{name:'LostPassword'}"
                  class="text-xs-left"
                >
                  <v-icon color="primary" size="small">ico-exclamation-circle</v-icon>
                  {{translations.login.password.lost}}!
                </router-link>
              </v-col>

              <Language :showText=true :activeOnly=true />

              <p v-if="attempt && authenticated === false" class="animated shake error-text">
                <v-icon color="red">ico-exclamation</v-icon>   <strong>{{translations.login.failure}}</strong>
              </p>

              <v-btn
                block
                :loading="loading"
                :disabled="username.length < 4 || password === null || password === ''"
                color="primary"
                v-ripple
                class="elevation-0"
                @click.prevent="recaptcha"
              >
                Login
                <v-icon class="pl-4">
                  ico-user
                </v-icon>
              </v-btn>

              <v-btn
                block
                color="primary"
                v-ripple
                class="elevation-0 mt-6"
                @click="$router.push({ name: 'Login' })"
              >
                Volver
              </v-btn>

            </v-card-text>

            <div v-if="false === showForm" class="animated fadeInUp">
              <v-container class="pt-5 pb-4">
                <v-row class="text-center">
                  <v-col cols="12">
                    <v-avatar align-center class="avatar pb-0" size="90">
                      <img :src="userData.avatar" />
                    </v-avatar>
                  </v-col>

                  <v-col cols="12">
                    {{translations.general.welcome}} {{userData.firstName}} {{userData.lastName}}
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Language from '@/components/ui/selection/Language'
  import Routes from '@/router/routes'

  export default {
    name: 'AuthForm',
    components:{
      Language
    },
    data(){
      return {
        attempt: false,
        passwordVisible: false,
        username: '',
        password: null,
        userData: {
          firstName: null,
          lastName: null,
          avatar: null
        },
        loggedInClass:'',
        showForm: true,
        avatarLoaded: false
      }
    },
    computed:{
      ...mapGetters({
        pending: 'services/api/auth/pending',
        authenticated: 'services/api/auth/authenticated',
        translations: 'services/api/translation/getTranslations',
      }),
      loading: function()
      {
        let me = this;
        return true === me.pending && false === me.avatarLoaded;
      },
      usernameErrors: function(){
        let me = this,
          errorMsg = me.translations.login.username.invalid;

        //Return translation from translation service
        return [];
      },
      passwordErrors: function(){
        let me = this,
          errorMsg = me.translations.login.password.invalid;
        return [];
      },
    },
    methods:{
      async recaptcha() {
        let me = this;

        await me.$recaptchaLoaded()
        const token = await me.$recaptcha('login')
        me.submit(token);
      },
      submit(token){
        let me = this;

        me.$store.dispatch('services/api/auth/doAuth', {
          username: me.username,
          password: me.password,
          captcha: token
        }).then(response => {

          if(false === me.authenticated){
            return me.attempt = true;
          }

          me.userData = response;

          let avatar = new Image();

          avatar.onload  = () => { me.loginAnimation() };
          avatar.onerror = () => { me.loginAnimation() };

          avatar.src = me.userData.avatar;

        })
      },
      loginAnimation: function(){
        let me = this;

        me.avatarLoaded = true;

        me.loggedInClass = 'animated fadeOutDown';
        setTimeout(()=>{
          me.showForm = false;
          setTimeout( () => {
            me.$router.push({name: "dashboard"});
          },1000);
        },500)

      }
    },
    props:{
      redirSuccess:{
        type:String
      },
      redirFailure:{
        type:String
      }
    }
  }
</script>

<style scoped type="scss">
  .logo-container{
    color:#1679d5;
    font-size:24px;
  }

  .logo-container .logo-img{
    float: left;
    width: 35px;
    fill: #1679d5;
  }
</style>
